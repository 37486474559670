<template max-width="10">
  <div class="px-4">
    <v-card
      class="d-flex align-center rounded-t-lg rounded-b-0 ma-4 mb-0 px-2 py-4"
      flat
    >
      <v-row>
        <v-col class="py-0 my-0" cols="12" md="8" sm="12">
          <v-tabs
            v-model="tab"
            icons-and-text
            show-arrows
            :class="$vuetify.breakpoint.smAndDown ? 'tabsWidth' : ''"
            @change="getGroups()"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#0">
              مجموعات الدردشة
              <v-icon>mdi-account-group</v-icon>
            </v-tab>

            <v-tab href="#1">
              المجموعات المؤرشفة
              <v-icon>mdi-archive</v-icon>
            </v-tab>

            <v-tab href="#2" v-if="isInRole(2)">
              المجموعات المعطلة
              <v-icon>mdi-message-bulleted-off</v-icon>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col
          v-if="isInRole(2)"
          :class="$i18n.locale == 'ar' ? 'text-left mt-1' : 'text-right mt-1'"
          cols="12"
          md="4"
          sm="12"
        >
          <v-btn
            color="error"
            outlined
            class="mx-1 mt-1"
            @click="actionGroup(2)"
            v-if="tab != 2"
          >
            <v-icon left> mdi-message-bulleted-off </v-icon>
            {{ $t("تعطيل") }}
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="mx-1 mt-1"
            @click="actionGroup(1)"
            v-if="tab != 1"
          >
            <v-icon left> mdi-archive </v-icon>
            {{ $t("ارشفة") }}
          </v-btn>
          <v-btn
            color="success"
            outlined
            class="mx-1 mt-1"
            @click="actionGroup(0)"
            v-if="tab != 0"
          >
            <v-icon left> mdi-account-group</v-icon>
            {{ $t("تفعيل") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      class="d-flex align-center rounded-t-0 rounded-b-lg ma-4 mt-0 pa-2 pb-0"
      flat
    >
      <DxDataGrid
        :showNavigationButtons="true"
        :row-alternation-enabled="true"
        :data-source="mails"
        :show-borders="true"
        key-expr="guid"
        :rtl-enabled="$i18n.locale == 'ar' ? true : false"
        :allow-column-resizing="false"
        :show-column-lines="true"
        @selection-changed="onSelectionChanged"

        :ref="dataGridRefName"
        :column-hiding-enabled="true"
      >
        <DxLoadPanel :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <DxColumn
          cell-template="groupFav"
          :caption="$t('المفضلة')"
          width="55"
        />
        <template #groupFav="{ data }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-checkbox
                v-model="data.data.groupFav"
                v-bind="attrs"
                v-on="on"
                on-icon="mdi-star"
                off-icon="mdi-star-outline"
                color="amber"
                @change="addFav(data.data)"
              ></v-checkbox>
            </template>
            <span>{{ $t("المفضلة") }}</span>
          </v-tooltip>
        </template>
        <DxColumn
          data-field="groupName"
          :caption="$t('اسم المجموعة')"
          :hiding-priority="4"
          :width="200"
        />

        <DxColumn
          data-field="groupMode"
          :caption="$t('وضع المجموعة')"
          :hiding-priority="2"
          cell-template="groupMode"
        />
        <template #groupMode="{ data }">
          {{
            data.data.groupMode == 1
              ? "يمكن للإستاذ فقط الإرسال"
              : "يمكن للجميع الإرسال	"
          }}
        </template>
        <DxColumn
          data-field="joinDate"
          :caption="$t('تاريخ الانضمام')"
          :hiding-priority="1"
          cell-template="cellTemplate"
        />
        <DxColumn
          cell-template="diff-cell-template"
          :caption="$t('فتح المجموعة')"
          width="100"
        />
        <template #diff-cell-template="{ data }">
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="data.data.invite"
                  icon
                  color="teal"
                  @click="openGroup(data.data)"
                  v-bind="attrs"
                  v-on="on"
                  :class="$i18n.locale == 'ar' ? 'pr-8' : 'pl-8'"
                >
                  <v-icon>mdi-login-variant </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("فتح المجموعة") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!data.data.invite"
                  icon
                  color="green"
                  @click="acceptDeclineInvite(1, data.data.guid)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-check </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("قبول الانضمام") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!data.data.invite"
                  icon
                  color="red"
                  @click="acceptDeclineInvite(2, data.data.guid)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("رفض الانضمام") }}</span>
            </v-tooltip>
          </div>
        </template>

        <DxColumn
          v-if="isInRole(2)"
          :hiding-priority="3"
          :caption="$t('mail.actions')"
          cell-template="template-action"
        />
        <template #template-action="{ data }" width="300">
          <div>
            <v-tooltip top v-if="tab == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="openInvite(data.data.guid)"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("دعوة اعضاء للمجموعة ") }}</span>
            </v-tooltip>
            <v-tooltip top v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="reportInvite(data.data.guid)"
                >
                  <v-icon>mdi-file-chart</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("تقرير الانضمام") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="deleteGroup(data.data)"
                  icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("حذف") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="editGroup(data.data)"
                  icon
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("تعديل") }}</span>
            </v-tooltip>
          </div>
        </template>
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-navigation-buttons="true"
          :show-info="false"
          :visible="true"
        />
        <DxSelection
          select-all-mode="page"
          show-check-boxes-mode="always"
          mode="multiple"
          v-if="isInRole(2)"
        />
        <DxFilterRow :visible="true" />
        <template #cellTemplate="{ data }">
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">
                  {{ data.data.datex2 }}
                </p>
              </template>
              <span>{{ data.data.joinDate }}</span>
            </v-tooltip>
          </div>
        </template>
      </DxDataGrid>
    </v-card>
    <v-fab-transition class="">
      <v-btn
        v-if="isInRole(2)"
        color="secondary"
        fab
        large
        elevation="6"
        fixed
        bottom
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        class="v-btn--example"
        :class="$store.state.drawerMessage ? 'ml-16' : ''"
                :style="!$vuetify.rtl?'right:80px':''"
        @click="openDialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <AddGroup
      v-if="isInRole(2)"
      ref="childComponent"
      v-on:send-result-values="getGroups"
    ></AddGroup>
    <InviteStudent
      v-if="isInRole(2)"
      ref="inviteStudent"
      v-on:send-result-values="getGroups"
    ></InviteStudent>
  </div>
</template>

<script>
import { DxSelectBox } from "devextreme-vue/select-box";
import moment from "moment";
import { mapMutations } from "vuex";
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxPager,
  DxSelection,
  DxFilterRow,
  DxButton,
  DxHeaderFilter,
  DxLoadPanel,
} from "devextreme-vue/data-grid";

export default {
  components: {
    AddGroup: () => import("../../components/chat/addGroup.vue"),
    InviteStudent: () => import("../../components/chat/inviteStudent.vue"),
    DxSelectBox,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSelection,
    DxFilterRow,
    DxButton,
    DxPager,
    DxHeaderFilter,
    DxLoadPanel,
  },
  data() {
    return {
      tab: 0,
      dataGridRefName: "dataGrid",

      pageSizes: [5, 10, 20],
      rtlEnabled: true,
      allMode: "allPages",
      checkBoxesMode: "onClick",
      mails: [],
      rowKeysSelected: [],
    };
  },
  computed: {},
  methods: {
    acceptDeclineInvite(memberStatus, groupID) {
      console.log(`${memberStatus} >>>>>>>>> ${groupID}`);
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .post(`ChatGroups/AcceptDeclineInvite`, {
                GroupGuid: groupID,
                MemberStatus: memberStatus,
              })
              .then(() => {
                this.getGroups();
                this.$swal.fire({
                  title: this.$t("operationAccomplishedSuccessfully"),
                  text: "",
                  icon: "success",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener(
                      "mouseleave",
                      this.$swal.resumeTimer
                    );
                  },
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("mail.ReadMessagesCannotBeDeleted"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {});
          }
        });
    },
    ...mapMutations({
      setGroupInfo: "SET_GROUPINFO",
    }),
    openGroup(data) {
      localStorage.setItem("groupInfo", JSON.stringify(data));
      this.setGroupInfo(data);
      this.$router.push({
        path: `chatOpen=${data.guid}`,
        params: { id: data.guid },
      });
    },
    reportInvite(groupID) {
      this.$router.push({
        path: `chatGroupReport=${groupID}`,
        params: { id: groupID },
      });
    },
    deleteGroup(data) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          // text: this.$t("TheMessagesReadByTheRecipientCannotBeDeleted"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .delete(`ChatGroups/DeleteGroup?groupGuid=${data.guid}`)
              .then(() => {
                this.getGroups();
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("mail.ReadMessagesCannotBeDeleted"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {});
          }
        });
    },
    editGroup(data) {
      this.$refs.childComponent.editValue(data);
    },
    actionGroup(GroupStatus) {
      this.axios
        .post("ChatGroups/ChangeGroupStatus", {
          GroupGuids: this.rowKeysSelected,
          GroupStatus: GroupStatus,
        })
        .then(() => {
          this.getGroups();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {});
    },
    addFav(data) {
      console.log(data);
      this.axios
        .put(`ChatGroups/AddToFavorite?groupGuid=${data.guid}`)
        .then(() => {
          this.getGroups();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {});
    },
    openDialog() {
      this.$refs.childComponent.setValue();
    },
    openInvite(groupGuid) {
      this.$refs.inviteStudent.openInvite(groupGuid);
    },
    onSelectionChanged(selectedRowsData) {
      this.rowKeysSelected = selectedRowsData.selectedRowKeys;
    },
    getGroups() {
      // console.log(this.tab);

      this.axios
        .get(`ChatGroups/Get?groupStatus=${this.tab}`)
        .then((res) => {
          this.mails = res.data.data;
          this.mails.forEach((e) => {
            if (e.joinDate) {
              e.datex2 = moment(e.joinDate).fromNow();
              e.joinDate = moment(e.joinDate).format("YYYY/MM/DD - h:mm a ");
              e.invite = true;
            } else {
              e.invite = false;
              e.datex2 = "لم يتم الانضمام بعد";
              e.joinDate = "لم يتم الانضمام بعد";
            }
          });
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
  created() {
    this.getGroups();
  },
};
</script>
<style lang="scss" scoped>
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.dx-htmleditor .dx-htmleditor-toolbar-wrapper:first-child {
  border-bottom: 1px solid #909090;
}
.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}
.dx-htmleditor {
  border-color: #7c7c7c;
}
.option {
  margin-top: 10px;
}
.v-list-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 1px;
  outline: none;
  padding: 0px;
  position: relative;
  text-decoration: none;
}
.tabsWidth {
  width: 300px;
}
</style>
